import {
  APIError,
  AuthError,
  ConflictError,
  EntityError,
  NotFoundError
} from "@/services/error_service";
import {
  LinkCategoryReq,
  ProductModel,
  ProductModelRequest,
  ProductRequestOptions,
  RelatedProductReq
} from "@/models/product";
import axios, { AxiosResponse } from "axios";

import { APIResponse } from "@/models/api_res";
import { AttributeModel } from "@/models/attribute";
import { Delta } from "@/models/delta";
import { DimensionModel } from "@/models/dimension";
import Vue from "vue";

/**
 * Creates a new Product Service

 * @example
 * const productService = new ProductService();
 * productService.getproduct();
 */

export default class ProductService {
  /**
   * Get paginated list of product
   * Filtered by parameters
   * @param {object} optionsObject :
   *  {
   *    @param {number} page page of results to return, optional
   *    @param {number} offset number of results to return, optional (default = 10 in the api, setting to 50 here)
   *    @param {number} mfr mfr id to filter by
   *    @param {number} category category id to filter by
   *    @param {string} q Query term for keyword search
   *    @param {boolean} empty Filters the resulting categories to only include empty categories.
   *    @param {boolean} hidden Filters the resulting categories to only include empty categories.
   *    @param {string} filter filter the products list by attribute values
   *    @param {string} bu required business unit value
   *        @example ...
   *          filter[length]=1&filter[length]=2&filter[width]=3
   *
   *
   *
   * @returns {APIResponse} - response object
   */
  public async getProducts(
    optionsObject: ProductRequestOptions
  ): Promise<APIResponse> {
    let products;
    let url = process.env.VUE_APP_API_URL_NODE + "/products";
    let counter = 0;
    let char = "?";
    if (optionsObject.page) {
      counter++;
      if (counter > 1) {
        char = "&";
      }
      url += char + "page=" + optionsObject.page;
    }
    if (optionsObject.offset) {
      counter++;
      if (counter > 1) {
        char = "&";
      }
      url += char + "per_page=" + optionsObject.offset;
    }
    if (optionsObject.q) {
      counter++;
      if (counter > 1) {
        char = "&";
      }
      url += char + "q=" + optionsObject.q;
    }
    if (optionsObject.mfr) {
      counter++;
      if (counter > 1) {
        char = "&";
      }
      url += char + "mfr=" + optionsObject.mfr;
    }
    if (optionsObject.category) {
      counter++;
      if (counter > 1) {
        char = "&";
      }
      url += char + "category=" + optionsObject.category;
    }
    if (optionsObject.filter) {
      counter++;
      if (counter > 1) {
        char = "&";
      }
      url += char + optionsObject.filter;
    }
    if (optionsObject.empty) {
      counter++;
      if (counter > 1) {
        char = "&";
      }
      url += char + "empty";
    }
    if (optionsObject.hidden) {
      counter++;
      if (counter > 1) {
        char = "&";
      }
      url += char + "hidden";
    }

    // required optionsObject business unit value
    counter++;
    if (counter > 1) {
      char = "&";
    }
    url += char + "bu=" + optionsObject.bu;

    // adding a "data tools only" request query since speeding up the website category page list pages
    url += "&data";

    try {
      const options = {
        withCredentials: true
      };
      const res = await axios.get(url);
      products = res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
    return products;
  }

  /**
   * POST new product to server
   * @param {Product} request - new product data object
   * @returns {Product} - newly created product object
   */
  public async createNewProduct(
    request: ProductModelRequest
  ): Promise<ProductModel> {
    let product;

    const options = {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    };
    const body = JSON.stringify(request);
    try {
      const res = await axios.post(
        process.env.VUE_APP_API_URL_NODE + "/products",
        body,
        options
      );
      product = res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      } else if (err.response && err.response.status === 409) {
        throw new ConflictError("Error: Product already exists.");
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
    return product;
  }

  /**
   * Get selected single product
   * @param {number} id - product id
   * @returns {Product} - selected product's data object
   */
  public async getSingleProduct(id: number): Promise<ProductModel> {
    let product: ProductModel;

    const options = {
      withCredentials: true
    };
    try {
      const res = await axios.get(
        process.env.VUE_APP_API_URL_NODE + "/products/" + id
      );
      product = res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      }
      if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested product not found.");
      } else {
        throw new APIError(
          "Something went wrong. Please try again.",
          err.status
        );
      }
    }
    return product;
  }

  /**
   * Save current product
   * @param {number} id - product id
   * @param {Product} request - product request data
   * @returns {Promise<void>}
   */
  public async saveProduct(
    request: ProductModelRequest,
    id: number
  ): Promise<void> {
    const options = {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    };
    const body = JSON.stringify(request);
    try {
      await axios.patch(
        process.env.VUE_APP_API_URL_NODE + "/products/" + id,
        body,
        options
      );
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      }
      if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested product not found.");
      } else if (err.response && err.response.status === 409) {
        throw new ConflictError("Error: Product already exists.");
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
  }

  /**
   * Delete product
   * @param {number} id - product id
   * @returns {Promise<void>}
   */
  public async deleteProduct(id: number): Promise<void> {
    try {
      const options = {
        withCredentials: true
      };
      return await axios.delete(
        process.env.VUE_APP_API_URL + "/products/" + id,
        options
      );
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      }
      if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested product not found.");
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
  }

  /**
   * Update or reorder an attribute on a product
   * @param {Delta} request - new value & delta of position change
   * @param {number} pid - product id
   * @param {number} aid - attribute id
   */
  public async editAttribute(
    id: number,
    aid: number,
    request: Delta
  ): Promise<void> {
    const options = {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    };
    const body = JSON.stringify(request);
    try {
      await axios.patch(
        process.env.VUE_APP_API_URL + "/products/" + id + "/attributes/" + aid,
        body,
        options
      );
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      } else if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested product not found.");
      } else if (err.response && err.response.status === 409) {
        throw new ConflictError("Error: Product already exists.");
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
  }

  /**
   * POST new attribute to product
   * Update or reorder an attribute on a product
   * @param {AttributeModel} request - attribute to be added to product
   * @param {number} id - product id
   */
  public async addAttribute(
    pid: number,
    request: AttributeModel
  ): Promise<void> {
    const options = {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    };
    const body = JSON.stringify(request);
    try {
      await axios.post(
        process.env.VUE_APP_API_URL + "/products/" + pid + "/attributes",
        body
      );
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      } else if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested product not found.");
      } else if (err.response && err.response.status === 409) {
        throw new ConflictError("Error: Product already exists.");
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
  }

  /**
   * Delete an attribute on a product
   * @param {number} pid - product id
   * @param {number} aid - attribute id
   */
  public async deleteAttribute(id: number, aid: number): Promise<void> {
    const options = {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    };
    try {
      await axios.delete(
        process.env.VUE_APP_API_URL + "/products/" + id + "/attributes/" + aid,
        options
      );
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      } else if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested product not found.");
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
  }

  /**
   * Update or reorder an dimension on a product
   * @param {Delta} request - new value & delta of position change
   * @param {number} pid - product id
   * @param {number} did - dimension id
   */
  public async editDimension(
    id: number,
    did: number,
    request: Delta
  ): Promise<void> {
    const options = {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    };
    const body = JSON.stringify(request);
    try {
      await axios.patch(
        process.env.VUE_APP_API_URL + "/products/" + id + "/dimensions/" + did,
        body,
        options
      );
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      } else if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested product not found.");
      } else if (err.response && err.response.status === 409) {
        throw new ConflictError("Error: Product already exists.");
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
  }

  /**
   * POST new dimension to product
   * Update or reorder an dimension on a product
   * @param {DimensionModel} request - dimension to be added to product
   * @param {number} id - product id
   */
  public async addDimension(
    pid: number,
    request: DimensionModel
  ): Promise<void> {
    const options = {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    };
    const body = JSON.stringify(request);
    try {
      await axios.post(
        process.env.VUE_APP_API_URL + "/products/" + pid + "/dimensions",
        body
      );
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      } else if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested product not found.");
      } else if (err.response && err.response.status === 409) {
        throw new ConflictError("Error: Product already exists.");
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
  }

  /**
   * Delete an dimension on a product
   * @param {number} pid - product id
   * @param {number} did - dimension id
   */
  public async deleteDimension(id: number, did: number): Promise<void> {
    const options = {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    };
    try {
      await axios.delete(
        process.env.VUE_APP_API_URL + "/products/" + id + "/dimensions/" + did,
        options
      );
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      } else if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested product not found.");
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
  }
  /**
   * POST new related product to product
   * @param {RelatedProduct} request - related product id
   * @param {number} pid - product id
   * @returns {void}
   */
  public async addRelatedProduct(
    pid: number,
    request: RelatedProductReq
  ): Promise<void> {
    const options = {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    };
    const body = JSON.stringify(request);
    try {
      await axios.post(
        process.env.VUE_APP_API_URL + "/products/" + pid + "/relations",
        body
      );
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      } else if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested product not found.");
      } else if (err.response && err.response.status === 409) {
        throw new ConflictError("Error: Product already exists.");
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
  }
  /**
   * Remove related product from product
   * @param {number} pid - product id
   * @param {number} rid - related product id
   * @returns {void}
   */
  public async deleteRelatedProduct(pid: number, rid: number): Promise<void> {
    const options = {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    };

    try {
      await axios.delete(
        process.env.VUE_APP_API_URL + "/products/" + pid + "/relations/" + rid
      );
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      } else if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested product not found.");
      } else if (err.response && err.response.status === 409) {
        throw new ConflictError("Error: Product already exists.");
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
  }

  /**
   * Remove related product from product
   * @param {number} pid - product id
   * @param {number} cid - category id
   * @returns {void}
   */
  public async unlinkCategory(pid: number, cid: number): Promise<void> {
    const options = {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    };

    try {
      await axios.delete(
        process.env.VUE_APP_API_URL + "/products/" + pid + "/categories/" + cid,
        options
      );
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      } else if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested product not found.");
      } else if (err.response && err.response.status === 409) {
        throw new ConflictError("Error: Product already exists.");
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
  }

  /**
   * POST new related product to product
   * @param {number} pid - product id
   * @param {RelatedProduct} request - related product id
   * @returns {void}
   */
  public async linkCategory(
    pid: number,
    request: LinkCategoryReq
  ): Promise<void> {
    const options = {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    };
    const body = JSON.stringify(request);
    try {
      await axios.post(
        process.env.VUE_APP_API_URL + "/products/" + pid + "/categories",
        body,
        options
      );
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      } else if (err.response && err.response.status === 404) {
        throw new NotFoundError(
          "Error: Requested product/destination not found."
        );
      } else if (err.response && err.response.status === 409) {
        throw new ConflictError(
          "Error: Product already exists in destination."
        );
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
  }
}
